<template>
  <div class="email-app-details">
    <modal-content-data
      v-show="isContentModalVisible"
      :selected_data="contentModal"
      :report_lang="$store.getters['user/getLang']"
      @close-modal-contents-data="isContentModalVisible = false"
    />
    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ lp.name }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">
        
        <template v-if="isReport">
          <feather-icon
            v-b-tooltip.hover.up="isItemChecked? $t('learning_path.remove'):$t('learning_path.add')"
            :icon="isItemChecked ? 'CheckSquareIcon' : 'SquareIcon'"
            size="17"
            class="cursor-pointer"
            @click="$emit('select-lp', lp)"
          />
        </template>
        <template v-else-if="lp.source">
          <!-- Edit LP -->
         <feather-icon
            v-b-tooltip.hover.up="$t('forms.edit')"
            v-if="lp.source.includes('own')"
            icon="EditIcon"
            class="ml-75 cursor-pointer"
            size="17"
            @click="$emit('edit-lp', lp)"
          /> 

          <!-- Copy LP -->
          <feather-icon
            v-b-tooltip.hover.up="$t('learning_path.save_copy')"
            icon="CopyIcon"
            class="ml-75 cursor-pointer"
            size="17"
            @click="$emit('copy-lp', lp)"
          />

          <!-- Move to Trash -->
          <feather-icon
            v-if="lp.source.includes('own')"
            v-b-tooltip.hover.up="$t('learning_path.delete')"
            icon="TrashIcon"
            class="ml-75 cursor-pointer text-danger"
            size="17"
            @click="$emit('delete-lp', lp)"
          />
        </template>
        <template v-if="isAllocation">
          <feather-icon
            v-b-tooltip.hover.up="$t('learning_path.assign_add_btn')"
            icon="SmileIcon"
            size="17"
            class="ml-1 cursor-pointer text-info"
            @click="$emit('allocate-lp', lp)"
          />
        </template>

      </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Email Thread -->
      <b-row>
        <b-col cols="12">
          <learning-path-detail-card 
            :lp="lp" />
        </b-col>
      </b-row>

      <!-- Email Actions: Reply or Forward -->
      <b-row v-if="lp.contents_obj">
        <b-col cols="12">
          <b-card
            no-body
            class="card-employee-task"
          >
            <b-card-header>
              <b-card-title>{{ lp.contents_obj.length}} {{ lp.contents_obj.length >1?$t('learning_path.activities') : $t('learning_path.activity')}} </b-card-title>
            </b-card-header>

            <!-- body -->
            <b-card-body>
              <div
                v-for="content in lp.contents_obj" :key="content.id"
                class="employee-task d-flex justify-content-between align-items-center"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                    variant="light-primary"
                      rounded
                      size="42"
                      :src="content.icon_url"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      {{ content.title }}
                    <span class="badge badge-light-success badge-pill"> {{$t('content_types.'+content.content_type)}} </span>
                    </h6>
                    <small>{{ content.description ? truncate(content.description) : '' }}</small>
                  </b-media-body>
                </b-media>
                <div class="d-flex align-items-center" @click="openModal(content.id)">
                  <b-button
                    variant="flat-info"
                    class="ml-2 btn-icon rounded-circle"
                  >
                    <feather-icon
                      icon="InfoIcon"
                    />
                  </b-button>
                </div>
              </div>
            </b-card-body>
          </b-card>
        
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
    BCardTitle, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody,
BButton,
  VBTooltip,
  BImg, BCardBody, BCardText, BDropdown, BDropdownItem, BRow, BCol, BBadge, BCard, BLink,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import LearningPathDetailCard from './LearningPathDetailCard.vue'
import ModalContentData from '@/views/modals/ModalContentData.vue'

export default {
  components: {
    BImg, BCardBody, BCardText, BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
BButton,
    
    BCardTitle, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody,

    // 3rd Party
    VuePerfectScrollbar,

    LearningPathDetailCard,
    ModalContentData
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    lp: {
      type: Object,
      required: true,
    },
    isAllocation:{
      type: Boolean,
      required: false,
      default: false
    },
    isReport:{
      type: Boolean,
      required: false,
      default: false
    },
    isItemChecked:{
      type:Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return{
      contentModal: null,
      isContentModalVisible: false
    }
  },
  methods:{
    truncate(str){
      return (str.length > 220) ? str.slice(0, 220-1) + '...' : str;
    },
    openModal(content_id) {
      this.contentModal = content_id;
      this.isContentModalVisible = true;
      this.$bvModal.show('modal-contents-data')
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    return {
      perfectScrollbarSettings,
    }
  },

}
</script>

<style lang="scss" scoped>
.activity-card{
  overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 3fr;
    
    .card-body[data-v-476fe675] {
    border-right: 1px solid #ebe9f1;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    }
}
.activity-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}
.item-img{
    height: 100%;
}
</style>
